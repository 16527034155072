import React from 'react';
import './css/App.css';
import Main from './Main';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    root: {
        backgroundColor: '#8FCFCD',
        width: '100%',
        height: '100%',
    },
});

function App() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Main/>
        </div>
    );
}

export default App;

import React from "react";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LucilleImg from "../images/macaw_background.jpg";
import LucilleLogo from "../images/lucille-logo.jpg";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#476A6F",
    height: "100%",
    minHeight: "80vh",
    borderRadius: "15px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.8)",
    marginTop: "20px",
  },
  sectionImg: {
    width: "100%",
    float: "left",
    borderRadius: "30px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.95)",
    "@media (max-width: 960px)": {
      marginBottom: 20,
    },
  },

  horizDivider: {
    backgroundColor: "#8FCFCD",
    width: "90%",
    marginLeft: "5%",
  },
  sectionHeader: {
    marginTop: 20,
    fontSize: 40,
    fontFamily: "Alex Brush",
    textAlign: "center",
    color: "#8FCFCD",
    "@media (max-width: 960px)": {
      fontSize: 25,
    },
  },
  sectionContent: {
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 15,
    color: "#8FCFCD",
    fontWeight: "bold",
    "@media (max-width: 960px)": {
      fontSize: 14,
    },
  },
  sectionContentImg: {
    height: "20vh",
    marginLeft: "15px",
    marginBottom: "15px",
    float: "right",
    borderRadius: "10px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.95)",
    "@media (max-width: 960px)": {
      marginBottom: 20,
      float: "none",
      margin: 0,
    },
  },
  sectionRow: {
    margin: "0px 25px 20px 25px",
    paddingTop: 50,
    paddingBottom: 50,
    borderTop: "1px solid #8FCFCD",
    borderBottom: "1px solid #8FCFCD",
    backgroundColor: "#7F675B",
    borderRadius: 10,
    "@media (max-width: 960px)": {
      paddingTop: 20,
      margin: "0px 10px 10px 10px",
    },
  },
  sectionRowSpacer: {
    height: 50,
  },
  sectionPara: {
    paddingBottom: 10,
    textIndent: "3vw",
  },
  sectionLeftSidebar: {
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    height: "100%",
    borderRight: "1px solid #8FCFCD",
    "@media (max-width: 960px)": {
      border: "none",
      paddingBottom: 20,
    },
  },
  sectionLeftSidebarButtons: {},
  sidebarButton: {
    color: "#8FCFCD",
    fontWeight: "bold",
    backgroundColor: "#476A6F",
    marginTop: 20,
    width: "100%",
    "&:hover": {
      backgroundColor: "#8FCFCD",
      color: "#476A6F",
    },
  },
});

function Home() {
  const classes = useStyles();
  return (
    <Row className={"no-gutters"}>
      <Col xs={12} className={classes.root}>
        <Row className={"no-gutters"}>
          <Col xs={12}>
            <div className={classes.sectionHeader}>About Us</div>
          </Col>
        </Row>
        <Row className={`no-gutters ${classes.sectionRow}`}>
          <Col lg={3}>
            <div className={classes.sectionLeftSidebar}>
              <Hidden only={["md", "sm", "xs"]}>
                <img
                  src={LucilleLogo}
                  className={classes.sectionImg}
                  alt="picture not found"
                />
                <div className={classes.sectionLeftSidebarButtons}>
                  <a
                    href="https://www.paypal.com/donate?hosted_button_id=QBNM9ZHFJFVBS"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      className={classes.sidebarButton}
                      startIcon={<AttachMoneyIcon />}
                    >
                      Donate
                    </Button>
                  </a>
                </div>
              </Hidden>
            </div>
          </Col>
          <Col lg={9}>
            <div className={classes.sectionContent}>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  src={LucilleImg}
                  className={classes.sectionContentImg}
                  alt="picture not found"
                />
              </div>

              <div className={classes.sectionPara}>
                Lucille's Voice Animal Education & Emergency Rescue is a 501c3
                non-profit organization with donations being tax deductible. Our
                highly qualified volunteers and board members are FEMA certified
                and animal cruelty investigations certified Veterinary
                Technicians. They specialize in livestock handling, exotic
                husbandry, domestic animal care, and more. Lucille's Voice is
                operated by volunteers and operates completely on donations.
              </div>
              <div className={classes.sectionPara}>
                Lucille's Voice was named after a captured wild Scarlett Macaw.
                She was trapped in the wild and brought to the United States
                during a time in which it was acceptable to import wild animals.
                After rescuing her, she lived a long life and brought such great
                joy and happiness to our family. In her memory, we decided to
                take matters into our own hands to help animals around the world
                with the hope that they would not endure the cruelty and neglect
                suffered by Lucille. After serving a lengthy volunteer
                deployment in the aftermath of hurricane Katrina, we established
                a program in our community to provide emergency services for
                evacuees during a natural disaster. We have since offered the
                same services to surrounding communities.
              </div>
              <div className={classes.sectionPara}>
                We believe that all animals deserve the right to live free from
                fear, abuse, neglect, torture, abandonment, or illegal capture.
                Some of our programs and services include:
              </div>
              <ul>
                <li>Animal rescue, adoption, and fostering</li>
                <li>Spay/neuter services to reduce over population</li>
                <li>
                  Support Veterans and their pets before, during, and after
                  deployment
                </li>
                <li>Community outreach programs</li>
                <li>Animal education programs</li>
                <li>Alternatives to pet re-homing</li>
                <li>Grieving pet owner groups</li>
              </ul>
              <Hidden only={["lg", "xl"]}>
                <img
                  src={LucilleLogo}
                  className={classes.sectionImg}
                  alt="picture not found"
                />
                <div className={classes.sectionLeftSidebarButtons}>
                  <a
                    href="https://www.paypal.com/donate?hosted_button_id=QBNM9ZHFJFVBS"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      className={classes.sidebarButton}
                      startIcon={<AttachMoneyIcon />}
                    >
                      Donate
                    </Button>
                  </a>
                </div>
              </Hidden>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Home;

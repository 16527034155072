import React from "react";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#476A6F",
    height: "100%",
    minHeight: "80vh",
    borderRadius: "15px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.8)",
    marginTop: "20px",
  },
  sectionHeader: {
    marginTop: 20,
    fontSize: 40,
    fontFamily: "Alex Brush",
    textAlign: "center",
    color: "#8FCFCD",
    "@media (max-width: 960px)": {
      fontSize: 25,
    },
  },
  sectionContent: {
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 15,
    color: "#8FCFCD",
    fontWeight: "bold",
    "@media (max-width: 960px)": {
      fontSize: 14,
    },
  },
  sectionRow: {
    margin: "0px 25px 20px 25px",
    paddingTop: 50,
    paddingBottom: 50,
    borderTop: "1px solid #8FCFCD",
    borderBottom: "1px solid #8FCFCD",
    backgroundColor: "#7F675B",
    borderRadius: 10,
    "@media (max-width: 960px)": {
      paddingTop: 20,
      margin: "0px 10px 10px 10px",
    },
  },
});

export default function GetInvolved() {
  const classes = useStyles();
  return (
    <Row className={"no-gutters"}>
      <Col xs={12} className={classes.root}>
        <Row className={"no-gutters"}>
          <Col xs={12}>
            <div className={classes.sectionHeader}>Get Involved</div>
          </Col>
        </Row>
        <Row className={`no-gutters ${classes.sectionRow}`}>
          <Col lg={12}>
            <div className={classes.sectionContent}>Under Construction</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@material-ui/core/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#476A6F",
    height: "100%",
    borderRadius: "0 0 15px 15px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.8)",
    padding: "15px",
    textAlign: "left",
    color: "#8FCFCD",
    display: "flex",
  },
  title: {
    display: "block",
    justifyContent: "flex-start",
    alignItems: "left",
  },
  headerTitle: {
    paddingLeft: "15px",
    fontFamily: "Alex Brush",
    fontSize: "50px",
    lineHeight: "80%",
    "@media (max-width: 960px)": {
      paddingLeft: "0",
      textAlign: "center",
    },
  },
  headerSubTitle: {
    paddingLeft: "15px",
    fontSize: "15px",
    lineHeight: "80%",
    "@media (max-width: 960px)": {
      paddingLeft: "0",
      textAlign: "center",
    },
  },

  navButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 960px)": {
      paddingTop: "15px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  smallNavDrawer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  smallNavDrawerButton: {
    textDecoration: "none !important",
    textTransform: "none !important",
    color: "#8FCFCD",
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#8FCFCD",
      color: "#476A6F",
    },
    "@media (max-width: 960px)": {
      fontSize: 10,
    },
  },

  buttonStyle: {
    textDecoration: "none",
    textTransform: "none",
    minWidth: "5vw",
    color: "#8FCFCD",
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#8FCFCD",
      color: "#476A6F",
    },
  },
  leftButton: {
    borderRadius: "5px 0 0 5px",
  },
  midButton: {
    borderRadius: "0",
  },
  rightButton: {
    borderRadius: "0 5px 5px 0",
  },
  dividerStyle: {
    backgroundColor: "#8FCFCD",
  },
  navBox: {
    width: "auto",
    height: "100%",
    backgroundColor: "#476A6F",
    color: "#8FCFCD",
  },
}));

function NavHeader() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [link, setLink] = React.useState();

  const getLink = (menuText) => {
    switch (menuText) {
      case "Home":
        return "";
      case "Adopt":
        return "adopt";
      case "Foster":
        return "foster";
      case "Donate":
        return "donate";
      case "Volunteer":
        return "volunteer";
      case "Get Involved":
        return "get-involved";
      case "Home":
        return "home";
    }
  };

  const list = () => (
    <Box
      className={classes.navBox}
      role="presentation"
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <List>
        {["Home", "Adopt", "Foster", "Donate", "Volunteer", "Get Involved"].map(
          (text, index) => (
            <div className={classes.smallNavDrawerButton} key={text}>
              <Link to={`/${getLink(text)}`} style={{ textDecoration: "none" }}>
                <ListItem>
                  <Button
                    className={clsx(classes.buttonStyle, classes.leftButton)}
                  >
                    {text}
                  </Button>
                </ListItem>
              </Link>
            </div>
          )
        )}
      </List>
    </Box>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <Row className={"no-gutters"}>
      <Col xs={12} className={classes.root}>
        <Row className={"no-gutters w-100 align-items-center"}>
          <Col lg={3} md={10}>
            <div className={classes.title}>
              <div className={classes.headerTitle}>Lucille's Voice</div>
              <div className={classes.headerSubTitle}>
                Animal Education & Emergency Rescue
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <Hidden only={["md", "sm", "xs"]}>
              <div className={classes.navButtons}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Button
                    className={clsx(classes.buttonStyle, classes.leftButton)}
                  >
                    Home
                  </Button>
                </Link>
                <Divider
                  className={classes.dividerStyle}
                  orientation="vertical"
                  flexItem
                />
                <Link to="/adopt" style={{ textDecoration: "none" }}>
                  <Button
                    className={clsx(classes.buttonStyle, classes.midButton)}
                  >
                    Adopt
                  </Button>
                </Link>
                <Divider
                  className={classes.dividerStyle}
                  orientation="vertical"
                  flexItem
                />
                <Link to="/foster" style={{ textDecoration: "none" }}>
                  <Button
                    className={clsx(classes.buttonStyle, classes.midButton)}
                  >
                    Foster
                  </Button>
                </Link>
                <Divider
                  className={classes.dividerStyle}
                  orientation="vertical"
                  flexItem
                />
                <Link to="/donate" style={{ textDecoration: "none" }}>
                  <Button
                    className={clsx(classes.buttonStyle, classes.midButton)}
                  >
                    Donate
                  </Button>
                </Link>
                <Divider
                  className={classes.dividerStyle}
                  orientation="vertical"
                  flexItem
                />
                <Link to="/volunteer" style={{ textDecoration: "none" }}>
                  <Button
                    className={clsx(classes.buttonStyle, classes.midButton)}
                  >
                    Volunteer
                  </Button>
                </Link>
                <Divider
                  className={classes.dividerStyle}
                  orientation="vertical"
                  flexItem
                />
                <Link to="/get-involved" style={{ textDecoration: "none" }}>
                  <Button
                    className={clsx(classes.buttonStyle, classes.rightButton)}
                  >
                    Get Involved
                  </Button>
                </Link>
              </div>
            </Hidden>
          </Col>
          <Col lg={3} md={2}>
            <Hidden only={["lg", "xl"]}>
              <div className={classes.smallNavDrawer}>
                <IconButton
                  color="inherit"
                  aria-label="open navigation"
                  onClick={handleDrawerOpen}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor={"top"}
                  open={open}
                  onClose={() => setOpen(false)}
                >
                  {list()}
                </Drawer>
              </div>
            </Hidden>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default NavHeader;

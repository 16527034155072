import React from "react";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#476A6F",
    height: "10vh",
    borderRadius: "15px 15px 0 0",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.8)",
    marginTop: "20px",
  },
});

function NavFooter() {
  const classes = useStyles();
  return (
    <Row className={"no-gutters"}>
      <Col xs={12} className={classes.root}></Col>
    </Row>
  );
}

export default NavFooter;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavHeader from "./components/NavHeader";
import Home from "./pages/Home";
import Adopt from "./pages/Adopt";
import Foster from "./pages/Foster";
import Donate from "./pages/Donate";
import Volunteer from "./pages/Volunteer";
import GetInvolved from "./pages/GetInvolved";
import NavFooter from "./components/NavFooter";
import { Row, Col } from "reactstrap";

function Main() {
  return (
    <BrowserRouter>
      <Row className={"no-gutters"}>
        <Col lg={1}> </Col>
        <Col lg={10}>
          <NavHeader />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/adopt" element={<Adopt />} />
            <Route path="/foster" element={<Foster />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/volunteer" element={<Volunteer />} />
            <Route path="/get-involved" element={<GetInvolved />} />
          </Routes>
          <NavFooter />
        </Col>
        <Col lg={1}> </Col>
      </Row>
    </BrowserRouter>
  );
}

export default Main;

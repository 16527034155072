import React, { useState, useEffect } from "react";
import client from "../client";
import { Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import BlockContent from "@sanity/block-content-to-react";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#476A6F",
    height: "100%",
    minHeight: "80vh",
    borderRadius: "15px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.8)",
    marginTop: "20px",
  },
  sectionHeader: {
    marginTop: 20,
    fontSize: 40,
    fontFamily: "Alex Brush",
    textAlign: "center",
    color: "#8FCFCD",
    "@media (max-width: 960px)": {
      fontSize: 25,
    },
  },
  sectionContent: {
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 15,
    color: "#8FCFCD",
    fontWeight: "bold",
    "@media (max-width: 960px)": {
      fontSize: 14,
    },
  },
  sectionRow: {
    margin: "0px 25px 20px 25px",
    paddingTop: 50,
    paddingBottom: 50,
    borderTop: "1px solid #8FCFCD",
    borderBottom: "1px solid #8FCFCD",
    backgroundColor: "#7F675B",
    borderRadius: 10,
    "@media (max-width: 960px)": {
      paddingTop: 20,
      margin: "0px 10px 10px 10px",
    },
  },
  cardColumn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardHeader: {
    size: 20,
    color: "#8FCFCD",
    fontWeight: "bold",
    backgroundColor: "#476A6F",
  },
  subHeader: {
    size: 20,
    color: "#8FCFCD",
    fontWeight: "bold",
    backgroundColor: "#476A6F",
  },
});

export default function Adopt() {
  const classes = useStyles();
  const [animals, setAnimals] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "animal"]{
              title,
              animalType,
              animalBreed,
              animalAge,
              availableOn,
              body,
              mainImage {
                  asset -> {
                    _id,  
                    url
                  }
              },
              alt
          }`
      )
      .then((data) => setAnimals(data))
      .catch(console.error);
  }, []);

  return (
    <Row className={"no-gutters"}>
      <Col xs={12} className={classes.root}>
        <Row className={"no-gutters"}>
          <Col xs={12}>
            <div className={classes.sectionHeader}>Adopt</div>
          </Col>
        </Row>
        <Row className={`no-gutters ${classes.sectionRow}`}>
          {animals.map((animal) => (
            <Col
              lg={4}
              className={classes.cardColumn}
              key={animal.mainImage.asset._id}
            >
              <Card
                style={{
                  borderRadius: "10px",
                  color: "#476A6F",
                  backgroundColor: "#8FCFCD",
                  margin: "10px",
                  boxShadow: "8px 8px 10px 0 rgba(0, 0, 0, 0.6)",
                }}
              >
                <CardHeader
                  title={animal.title}
                  subheader={
                    <Typography className={classes.subColor}>
                      {`Available: ${animal.availableOn.slice(0, -14)}`}
                    </Typography>
                  }
                />
                <CardMedia
                  component="img"
                  height="194"
                  image={animal.mainImage.asset.url}
                  alt="Paella dish"
                />
                <CardContent>
                  <BlockContent
                    blocks={animal.body}
                    projectId="801bv2l2"
                    dataset="production"
                  />
                </CardContent>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
}
